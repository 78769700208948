import * as React from 'react';
import { Text } from '@chakra-ui/react';
import { Box } from '@chakra-ui/layout';

import styled from '@emotion/styled';

interface ITagElementProps {
    children: React.ReactNode | React.ReactElement | string;
}

class TagElement extends React.Component<ITagElementProps> {
    public render() {
        return (
            <TagHeader>
                <TagText>
                    {this.props.children}
                </TagText>
            </TagHeader>
        )
    }
}


export default TagElement;

const TagHeader = styled(Box)`
    /* Auto Layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    padding: 12.1px 22px;

    position: static;
    width: max-content;
    height: 38px;
    left: 0px;
    top: 0px;

    background: ${p => p.theme.colors.hover};
    box-shadow: 0px 16.5px 27.5px rgba(0, 0, 0, 0.1);
    border-radius: 100px;

    /* Inside Auto Layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 36px 0px;
`

const TagText = styled(Text)`
    /* Text */
    font-weight: bold;
    font-size: 13.2px;
    line-height: 15px;
    letter-spacing: 0.25em;
    text-transform: uppercase;
    color: ${p => p.theme.colors.accent};
`
