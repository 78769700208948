import * as React from 'react';
import styled from '@emotion/styled';

import { Flex, Box } from '@chakra-ui/layout';
import { Badge, Text } from '@chakra-ui/react';

const Content = {
    title: 'Full Stack Cloud Native SaaS App Dev',
    subtitle:
        'React.js + C# + GraphQL + CI/CD + Figma // \n Azure // AWS // GCP // EDGE',
    header: 'About Me',
    text: {
        value: "Hey, I'm a 2X saas founder with a hunger for empowering growth minded teams with Cloud Native App Dev. \n \n In 2007, I earned the microsoft young innovator award and have since launched and crashed many startup projects. but I’ve learned a ton and fueled this energy into the next big thing, like the snowball effect. \n \n  Today I’m building deep learning backed SaaS platforms and running a consulting agency as a multi-cloud strategy partner. \n \n The way I think is case based, customer-facing impact, reverse-engineered back down to the code and measurable data-driven metrics. \n \n I’m learning every day, follow my journey on dev social.",
        accent: 'founder // saas // architect // developer',
    },
};

class AboutMe extends React.Component {
    public render() {
        return (
            <Flex flexWrap={'nowrap'}>
                <Box width={'90%'}>
                    <Badge
                        size={'lg'}
                        colorScheme={'black'}
                        my={3}
                        p={2}
                        sx={{
                            color: 'red.500',
                            border: '1px',
                            borderColor: 'red.600',
                            borderRadius: 'lg',
                        }}
                    >
                        {Content.header}
                    </Badge>
                    <Box>
                        <Title>{Content.title}</Title>
                        <Subtitle>{Content.subtitle}</Subtitle>
                    </Box>
                    <Box my={[5, 7]}>
                        <LargeText>{Content.text.value}</LargeText>
                    </Box>
                </Box>
                <Box w={'20%'}>
                    <VerticalText>{Content.text.accent}</VerticalText>
                </Box>
            </Flex>
        );
    }
}

export default AboutMe;

const Title = styled(Text)`
    font-size: 16pt;
`;

const Subtitle = styled(Text)`
    font-size: 12pt;
`;

const LargeText = styled(Text)`
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 1.5;
    text-transform: uppercase;
`;

const VerticalText = styled(Text)`
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 66px;
    text-align: right;
    letter-spacing: 0.25em;
    text-transform: uppercase;

    display: flex;
    align-items: flex-end;
    transform: rotate(-90deg);
    width: max-content;
`;
