import styled from '@emotion/styled';
import { Text } from '@chakra-ui/react';

const AccentTitle = styled(Text)`
    font-style: normal;
    font-weight: 100;
    font-size: 72px;
    line-height: 87px;
    text-align: center;
    letter-spacing: 1em;
    text-transform: uppercase;
    color: ${p => p.theme.colors.accent};
`

export default AccentTitle;