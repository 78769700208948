import styled from '@emotion/styled';
import { Text } from '@chakra-ui/react';

const PrimarySubtitle = styled(Text)`
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    color: ${p => p.theme.colors.primary};

    text-align: center;
    text-transform: uppercase;
`

export default PrimarySubtitle;