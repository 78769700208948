/**
 * @type { }
 */
const AppRegistry = {
    BaseUrl: "https://sarmalik.com",
    Title: {
        Default: 'Sar Malik | Cloud Strategy & Implementation Consultant - Toronto | London',
        Short: "Cloud + AI Consultant",
        Main: 'Sar Malik'
    },
    Description: {
        Meta: "Hey, I build digital experiences to empower Fortune 500 companies like Microsoft with Cloud & AI. Let's chat.",
        Hero: 'Hey, I build %cloud experiences% to empower Fortune 500 clients',
        ArticleLead: 'Let us empower your team with a #DigitalTransformation strategy session covering impactful Cloud + AI solutions.'
    },
    Tags: {
        Default: [
            'Digital Transformation',
            'Business',
            'Management',
            'Leadership',
            'Insights',
            'App Dev',
            'Cloud',
            'Azure',
            'AWS',
            'GCP'
        ],
        Compose: [
            'Digital Transformation',
            'Artificial Intelligence',
            'Cloud Architecture',
            'DevOps',
            'Advanced Analytics',
            'Operating Models',
            'Leadership',
            'Digital M+A',
            'Growth Strategy'
        ]
    },
    Copyright: {
        Company: 'Sar Malik',
        Author: 'Sar Malik',
        Year: 2021
    },
    Social: [
        {
            name: `twitter`,
            url: `https://twitter.com/tcpnet`,
        },
        {
            name: `linkedin`,
            url: `https://www.linkedin.com/in/sarmalik/`,
        },
        {
            name: `dribbble`,
            url: `https://dribbble.com/SarMalik`,
        },
        {
            name: `github`,
            url: `https://github.com/SarMalik`,
        },
    ],
    Icon: {
        "Default": "src/assets/sar.malik.profile.png",
        "LogoColor": "white",
    },
    Author: {
        Default: 'Sar Malik'
    },
    Contact: {
        Mail: 's@sarmalik.com'
    }
};

export default AppRegistry;
