import * as React from 'react';
import { Flex, Box } from '@chakra-ui/layout';
import { Text } from '@chakra-ui/react';

const content = {
    title: 'dashbear | Customer 360 Marketing platform',
}

class PrimaryContent extends React.Component {
    public render() {
        return (
            <Flex w={'100%'} flexWrap={'wrap'} m={[2, 3]} p={[2, 3]} sx={{  bg: 'gray.100' }}>
                {/* TODO: Inner content */}
            </Flex>
        )
    }
}


export default PrimaryContent;
