import AppRegistry from './AppRegistry';

/**
 * Gatsby:Site:Metadata
 */
export const Metadata = {
    title: 'Sar Malik | 2X Founder, Cloud Native Developer, Deep Tech',
    name: 'Sar Malik',
    short: 'Low-Code No-Code AI at your fingertips',
    description:
        'I build and launch launch SaaS products with a Cloud Native stack - GraphQL API backend and high performance inference.',
    author: `@tcpnet`,
    siteUrl: AppRegistry.BaseUrl,
    hero: {
        heading: AppRegistry.Description.Hero,
        maxWidth: 640,
    },
    social: AppRegistry.Social,
};
