import * as React from 'react';
import { Flex, Box } from '@chakra-ui/layout';

import { TagElement } from '../../components/Elements';
import PrimaryContent from './PrimaryContent';

const Content = {
    label: 'Building Now',
}

class BuildingNow extends React.Component {
    public render() {
        return (
            <Flex>
                <Box w={[1, 1/3]}>
                    <TagElement>
                        {Content.label}
                    </TagElement>
                </Box>
                <Box w={1}>
                    {/* TODO: PrimaryContent container */}
                    <PrimaryContent />
                </Box>
            </Flex>
        )
    }
}

export default BuildingNow;
