import * as React from 'react';

interface IChevronElementProps {
    fill?: string;
}

class ChevronElement extends React.Component<IChevronElementProps> {
    public render() {
        return (
            <>
                <svg width="29" height="61" viewBox="0 0 29 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.6641 59.375V1.625M27.4974 46.5417L14.6641 59.375L27.4974 46.5417ZM14.6641 59.375L1.83072 46.5417L14.6641 59.375Z" stroke={this.props.fill ?? "white"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </>
        )
    }
}

export default ChevronElement;