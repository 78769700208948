import * as React from 'react';

import { Flex, Box } from '@chakra-ui/layout';
import { AccentTitle, PrimarySubtitle } from '../../components/Elements';
import BuildingNow from './BuildingNow';

const Content = {
    title: "Impact",
    subtitle: "Here's what I've landed recently"
}

class Impact extends React.Component {
    public render() {
        return (
            <Flex flexWrap={'wrap'}>
                <Box width={'100%'}>
                    <PrimarySubtitle>{Content.subtitle}</PrimarySubtitle>
                    <AccentTitle>{Content.title}</AccentTitle>
                </Box>
                <Box>
                    <BuildingNow />
                </Box>
            </Flex>
        )
    }
}

export default Impact;
