import * as React from 'react';
import { Button, Link, Text } from '@chakra-ui/react';

class Contact extends React.Component {
    public render(): React.ReactNode {
        return (
            <>
                <Link href={'mailto:malik@sarthak.ca?subject=Hi'}>
                    <Button size={'md'} colorScheme={'red'}>
                        <Text>{'Get in Touch'}</Text>
                    </Button>
                </Link>
            </>
        );
    }
}

export default Contact;
