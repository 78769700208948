import * as React from 'react';
import Section from '../../components/Layout/Section';
import { AboutMe, Impact } from '../blocks';
import Contact from '../blocks/Contact';

class Home extends React.Component {
    public render(): React.ReactNode {
        return (
            <>
                <Section narrow>
                    <AboutMe />
                    <Contact />
                </Section>
            </>
        );
    }
}

export default Home;
