import * as React from 'react';
import { AppContainer, Layout } from '../components/Layout';
import { Metadata } from '../meta/Metadata';
import SEO from '../meta/SEO';
import MainCoverImpl from '../sections/cover/MainCoverImpl';
import Home from '../sections/pages/Home';

class Index extends React.Component {
    public render() {
        return (
            <AppContainer>
                <SEO title={Metadata.title} />
                <Layout cover={<MainCoverImpl />}>
                    <Home />
                </Layout>
            </AppContainer>
        );
    }
}

export default Index;
